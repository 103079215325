
import { Vue } from 'vue-class-component'
import { copy } from '@/utils'
export default class Login extends Vue {
  visible: boolean = false

  onOpenTaoBao (): void {
    copy('8淘宝超级红包第二弹，復制整条，去🍑桃寶￥CQ4jcQHhzzN￥领取，金额超高！上车啰！也可分享他人哦/')
    this.$toast({
      message: '口令复制成功，请到淘宝APP打开',
      duration: 2500
    })
    this.visible = false
    this.$DR.pageReportFn({ subModule: 'HGXM_1111Page_TaoBaoOpen' })
  }

  onCopyTaoBao (): void {
    copy('8淘宝超级红包第二弹，復制整条，去🍑桃寶￥CQ4jcQHhzzN￥领取，金额超高！上车啰！也可分享他人哦/')
    this.$toast({
      message: '口令复制成功，请到淘宝APP打开',
      duration: 2500
    })
    this.$DR.pageReportFn({ subModule: 'HGXM_1111Page_TaoBao' })
  }

  onCopyEle (): void {
    copy('1👈fu至这行话￥UANXcSJx0OR￥到👉氵匋寳👈或掂击链街 https://m.tb.cn/h.40f5qRx 至浏.览览.器【每日领饿了么餐饮红I包】')
    this.$toast({
      message: '口令复制成功，请到淘宝APP打开',
      duration: 2500
    })
    this.$DR.pageReportFn({ subModule: 'HGXM_1111Page_Ele' })
  }

  onJumpMeituan (): void {
    window.open('https://dpurl.cn/jy5zcuk')
    this.$DR.pageReportFn({ subModule: 'HGXM_1111Page_Meituan' })
  }

  onJumpIqiyi (): void {
    window.open('https://hgxm.youshds.com/iqiyi')
    this.$DR.pageReportFn({ subModule: 'HGXM1111Page_Iqiyi' })
  }

  onJumpKfc (): void {
    window.open('https://tb.g2h3.com/4AD8I')
    this.$DR.pageReportFn({ subModule: 'HGXM1111Page_Kfc' })
  }

  mounted (): void {
    this.visible = true
  }
}
