<template>
  <div class="double-11">
    <div class="card">
      <div class="text important">双十一电商公司忙成狗</div>
      <div class="text important">女朋友让我做一份攻略去剁手</div>
      <div class="text important">设计小姐姐不接我的私人需求</div>
      <div class="text important">无奈只能亲自上手，，，</div>
      <img
        class="image"
        style="margin-top: 10px;"
        src="@/assets/images/1111/0.png">
    </div>
    <div class="card">
      <div class="title">Jack （淘） Ma（宝）红包 每天可领 最高</div>
      <div class="title important">1111元</div>
      <img
        class="image"
        style="margin-top: 10px;"
        src="@/assets/images/1111/1.png">
      <div
        class="text link"
        style="margin-top: 10px;">8淘宝超级红包第二弹，復制整条，去🍑桃寶￥CQ4jcQHhzzN￥领取，金额超高！上车啰！也可分享他人哦/</div>
      <button
        class="button taobao"
        @click="onCopyTaoBao">领取<span class="important">1111</span>元</button>
    </div>
    <div class="card">
      <div class="title">对了，还有东哥的京享红包</div>
      <img
        class="image"
        style="margin-top: 10px;"
        src="@/assets/images/1111/2.png">
      <div
        class="subtitle"
        style="margin-top: 10px;">别急，还没开启呢，稍后更新（11月1日）</div>
    </div>
    <div class="card">
      <div class="row">
        <div class="content">
          <div class="text">啥也不说了，省个肾给女朋友用吧</div>
          <div class="text">每日一次，你懂得~~</div>
        </div>
        <img
          class="image icon"
          src="@/assets/images/1111/7.png">
      </div>
      <div class="row">
        <img
          class="image"
          src="@/assets/images/1111/3.png">
        <button
          class="button meituan"
          @click="onJumpMeituan">点我<br>领取</button>
      </div>
      <img
        class="image"
        style="margin-top: 10px;"
        src="@/assets/images/1111/4.png">
      <div
        class="text link"
        style="margin-top: 10px;">1👈fu至这行话￥UANXcSJx0OR￥到👉氵匋寳👈或掂击链街 https://m.tb.cn/h.40f5qRx 至浏.览览.器【每日领饿了么餐饮红I包】</div>
      <button
        class="button ele"
        @click="onCopyEle">复制口令</button>
    </div>
    <div class="card">
      <div class="row">
        <img
          class="image"
          src="@/assets/images/1111/5.png">
        <button
          class="button iqiyi"
          @click="onJumpIqiyi">点我<br>八折</button>
      </div>
    </div>
    <div class="card">
      <div class="row">
        <img
          class="image"
          src="@/assets/images/1111/6.png">
        <button
          class="button kfc"
          @click="onJumpKfc">点我<br>五折</button>
      </div>
    </div>
    <div class="footer">
      <div class="title">程序猿小哥尽力了！</div>
      <img
        class="image"
        src="@/assets/images/1111/8.png">
      <div class="prompt">每天持续不断更新ing~</div>
    </div>
    <transition name="modal-fade">
      <div
        v-show="visible"
        class="modal">
        <transition name="scale-fade">
          <div
            v-show="visible"
            class="container">
            <img
              class="image"
              src="@/assets/images/1111/9.png">
            <button
              class="button"
              @click="onOpenTaoBao"/>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { copy } from '@/utils'
export default class Login extends Vue {
  visible: boolean = false

  onOpenTaoBao (): void {
    copy('8淘宝超级红包第二弹，復制整条，去🍑桃寶￥CQ4jcQHhzzN￥领取，金额超高！上车啰！也可分享他人哦/')
    this.$toast({
      message: '口令复制成功，请到淘宝APP打开',
      duration: 2500
    })
    this.visible = false
    this.$DR.pageReportFn({ subModule: 'HGXM_1111Page_TaoBaoOpen' })
  }

  onCopyTaoBao (): void {
    copy('8淘宝超级红包第二弹，復制整条，去🍑桃寶￥CQ4jcQHhzzN￥领取，金额超高！上车啰！也可分享他人哦/')
    this.$toast({
      message: '口令复制成功，请到淘宝APP打开',
      duration: 2500
    })
    this.$DR.pageReportFn({ subModule: 'HGXM_1111Page_TaoBao' })
  }

  onCopyEle (): void {
    copy('1👈fu至这行话￥UANXcSJx0OR￥到👉氵匋寳👈或掂击链街 https://m.tb.cn/h.40f5qRx 至浏.览览.器【每日领饿了么餐饮红I包】')
    this.$toast({
      message: '口令复制成功，请到淘宝APP打开',
      duration: 2500
    })
    this.$DR.pageReportFn({ subModule: 'HGXM_1111Page_Ele' })
  }

  onJumpMeituan (): void {
    window.open('https://dpurl.cn/jy5zcuk')
    this.$DR.pageReportFn({ subModule: 'HGXM_1111Page_Meituan' })
  }

  onJumpIqiyi (): void {
    window.open('https://hgxm.youshds.com/iqiyi')
    this.$DR.pageReportFn({ subModule: 'HGXM1111Page_Iqiyi' })
  }

  onJumpKfc (): void {
    window.open('https://tb.g2h3.com/4AD8I')
    this.$DR.pageReportFn({ subModule: 'HGXM1111Page_Kfc' })
  }

  mounted (): void {
    this.visible = true
  }
}
</script>

<style lang="scss" scoped>
@keyframes scale {
  from {
    transform: scale(1, 1) translateY(0);
  }
  to {
    transform: scale(1.06, 1.06) translateY(-5px);
  }
}

@keyframes translate {
  from {
    transform: translateY(0);
  }
  to   {
    transform: translateY(-20px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(15deg);
  }
  to   {
    transform: rotate(-15deg);
  }
}

@keyframes modal-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modal-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scale-fade-in {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes scale-fade-out {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 0);
  }
}

.modal-fade-enter-active {
  animation: modal-fade-in 1000ms;
}

.modal-fade-leave-active {
  animation: modal-fade-out 1000ms;
}

.scale-fade-enter-active {
  animation: scale-fade-in 1000ms;
}

.scale-fade-leave-active {
  animation: scale-fade-out 1000ms;
}
.double-11 {
  padding: 10px;
  .image {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
  .card {
    box-shadow: 1px 1px 4px 1px rgba(#7a7a7a, 0.2);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    .text {
      color: #333;
      font-size: 14px;
      line-height: 30px;
      &.important {
        font-size: 20px;
        font-weight: bold;
        color: #2802fd;
        background-image: linear-gradient(#02b6fd,  #2802fd);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 1px 1px 4px rgba(#2802fd, 0.5);
      }
      &.link {
        color: #02b6fd;
      }
    }
    .title {
      color: #e60029;
      background-image: linear-gradient(#e60029,  #e6cf00);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 1px 1px 4px rgba(#e6cf00, 0.5);
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      line-height: 30px;
      &.important {
        font-size: 26px;
        margin-top: 10px;
      }
    }
    .subtitle {
      color: #333;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
    }
    .button {
      display: block;
      width: 200px;
      height: 40px;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 5px;
      line-height: 40px;
      outline: none;
      margin: 20px auto 0 auto;
      animation: scale 500ms linear 0ms infinite alternate;
      &.taobao {
        width: 300px;
        height: 60px;
        background-color: #e60029;
        box-shadow: 1px 5px 10px 1px rgba(#e60029, 0.3);
        line-height: 60px;
      }
      &.ele {
        background-color: #02b6fd;
        box-shadow: 1px 5px 10px 1px rgba(#02b6fd, 0.3);
      }
      .important {
        font-size: 24px;
        font-weight: bold;
      }
    }
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .image {
        display: block;
        max-width: 70%;
        margin: 0;
        &.icon {
          animation: translate 300ms linear 0ms infinite alternate;
        }
      }
      .button {
        width: 60px;
        height: 60px;
        font-size: 16px;
        border-radius: 30px;
        line-height: 20px;
        margin: 0;
        animation: scale 300ms linear 0ms infinite alternate;
        &.meituan {
          background-color: #ffc300;
          box-shadow: 1px 5px 10px 1px rgba(#ffc300, 0.3);
        }
        &.iqiyi {
          background-color: #00cc4c;
          box-shadow: 1px 5px 10px 1px rgba(#00cc4c, 0.3);
        }
        &.kfc {
          background-color: #e60029;
          box-shadow: 1px 5px 10px 1px rgba(#e60029, 0.3);
        }
      }
    }
  }
  .footer {
    margin-top: 40px;
    .title {
      color: #e60029;
      text-shadow: 1px 1px 4px rgba(#e60029, 0.5);
      font-size: 20px;
      text-align: center;
      animation: rotate 300ms linear 0ms infinite alternate;
    }
    .image {
      width: 200px;
    }
    .prompt {
      text-align: center;
    }
  }
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.8);
    .container {
      position: relative;
      width: 375px;
      height: 667px;
      .image {
        display: block;
        width: 375px;
        height: 667px;
      }
      .button {
        display: block;
        width: 90px;
        height: 110px;
        background-color: transparent;
        border: 0;
        outline: none;
        position: absolute;
        top: 370px;
        left: 50%;
        margin-left: -45px;
      }
    }
  }
}
</style>
